import React, { useEffect, useState } from 'react'
import * as S from './styles'

import { useTranslation } from 'react-i18next'

const Blog = () => {
  const { t } = useTranslation()

  const [subtitlesNewsletter, setSubtitlesNewsletter] = useState('')
  const [placeholdersEmail, setPlaceholdersEmail] = useState('')
  const [buttonsGetNews, setButtonsGetNews] = useState('')

  useEffect(() => {
    setSubtitlesNewsletter(t('subtitles.newsletter'))
    setPlaceholdersEmail(t('placeholders.email'))
    setButtonsGetNews(t('buttons.getNews'))
  }, [t])

  return (
    <S.BlogContainer>
      <h2>BLOG</h2>
    </S.BlogContainer>
  )
}

export default Blog
