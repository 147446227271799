import React, { useEffect, useState } from 'react'
import * as S from './styles'

import Availability from '@/components/LandingPage/Availability'
import NewModuleTag from '@/components/LandingPage/NewModuleTag'

import { PAGE_PATHS } from '@/services/paths'

import { useTranslation } from 'react-i18next'

const ContentItem = ({ data, parentIndex, index }) => {
  const { t } = useTranslation()

  const [title, setTitle] = useState('')
  const [text, setText] = useState('')
  const [buttonText, setButtonText] = useState('')

  useEffect(() => {
    setTitle(t(data.title))
    setText(t(data.text))
    setButtonText(t(data.buttonText))
  }, [t])

  return (
    data && (
      <S.ContentItemContainer id={`content-${parentIndex}-${index}`}>
        <S.ContentItemImgContainer className="mobile-only">
          <video preload="auto" playsInline loop muted autoPlay>
            <source type="video/webm" src={`${data.image}.webm`} />
            <source type="video/mp4" src={`${data.image}.mp4`} />
            Your browser does not support the video tag.
          </video>
        </S.ContentItemImgContainer>

        <S.ContentItemTextContainer>
          {data.newUntil && <NewModuleTag expDate={data.newUntil} />}
          <h3>{title}</h3>
          <p>{text}</p>
          <Availability platforms={data.available} />
          <a
            href={`${PAGE_PATHS.contents}?section=${parentIndex}&subsection=${index}`}
            id={`btn_contents_${parentIndex}_${index}`}
            className="btn lg blue2 round shadow"
          >
            <span>{buttonText}</span>
            <span className="material-icons-round">arrow_forward</span>
          </a>
        </S.ContentItemTextContainer>

        <S.ContentItemImgContainer className="desktop-only">
          <video preload="auto" playsInline loop muted autoPlay>
            <source type="video/webm" src={`${data.image}.webm`} />
            <source type="video/mp4" src={`${data.image}.mp4`} />
            Your browser does not support the video tag.
          </video>
        </S.ContentItemImgContainer>
      </S.ContentItemContainer>
    )
  )
}

export default ContentItem
