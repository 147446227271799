import styled from 'styled-components'

export const FeatureItemContainer = styled.div`
  h3 {
    font-size: 18px;
    line-height: 22px;
    color: var(--grey19);
    margin-bottom: 0.5rem;
  }

  p {
    font-size: 14px;
    line-height: 22px;
  }

  @media only screen and (max-width: 700px) {
    text-align: center;
  }
`
