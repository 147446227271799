import React, { useEffect, useState } from 'react'
import * as S from './styles'

import ImageNext from '@/components/ImageNext'

const iconSize = 25

const FeatureIcon = ({ name, baseURL }) => {
  const [iconURL, setIconURL] = useState()

  useEffect(() => {
    if (!name) return
    setIconURL(`${baseURL}/${name}.svg`)
  }, [name])

  return (
    iconURL && (
      <S.FeatureIconContainer>
        <ImageNext src={iconURL} width={iconSize} height={iconSize} />
      </S.FeatureIconContainer>
    )
  )
}

export default FeatureIcon
