import React, { useEffect, useState } from 'react'
import * as S from './styles'

import { useWindowSize } from '@/contexts/WindowSizeContext'

import ContentMenu from './ContentMenu'
import ContentItem from './ContentItem'
import Decoration from '@/components/LandingPage/Decoration'

import { isValueDefined } from '@/services/functions'

import { useTranslation } from 'react-i18next'

const ContentSection = ({ data, index }) => {
  const { t } = useTranslation()
  const { windowSize } = useWindowSize()

  const [title, setTitle] = useState('')

  const [selectedContent, setSelectedContent] = useState()
  const [previousContent, setPreviousContent] = useState()

  useEffect(() => {
    if (!data) return
    setTitle(t(data.title))
  }, [t, data])

  useEffect(() => {
    if (!isValueDefined(selectedContent)) return

    if (isValueDefined(previousContent)) {
      const prev = document.getElementById(
        `content-${index}-${previousContent}`
      )
      prev.classList.remove('visible')
      prev.style.display = 'none'
    }

    const div = document.getElementById(`content-${index}-${selectedContent}`)
    div.style.display = windowSize.width >= 1050 ? 'flex' : 'block'
    setTimeout(() => div.classList.add('visible'), 50)

    setPreviousContent(selectedContent)
  }, [selectedContent])

  return (
    data && (
      <S.ContentSectionContainer
        id={`content-section-${index}`}
        color={data.color}
      >
        {data.decoration?.map((decor, i) => (
          <Decoration key={i} data={decor} />
        ))}
        <h2>{title}</h2>
        <ContentMenu data={data} handler={setSelectedContent} />
        {data.content.map((item, i) => (
          <ContentItem key={i} data={item} parentIndex={index} index={i} />
        ))}
      </S.ContentSectionContainer>
    )
  )
}

export default ContentSection
