import React, { useEffect, useState } from 'react'
import * as S from './styles'

import { useWindowSize } from '@/contexts/WindowSizeContext'

import Decoration from '@/components/LandingPage/Decoration'

import { useTranslation } from 'react-i18next'

const Testimonials = ({ data }) => {
  const { t } = useTranslation()
  const { windowSize } = useWindowSize()

  const [quote, setQuote] = useState('')
  const [position, setPosition] = useState('')

  const [imgSize, setImgSize] = useState('')

  useEffect(() => {
    if (!data) return

    setQuote(t('subtitles.testimonial0_0'))
    setPosition(t('subtitles.testimonial0_1'))
  }, [t, data])

  useEffect(() => {
    if (!windowSize.width) return

    if (windowSize.width < 700) {
      setImgSize(102)
    } else if (windowSize.width < 1050) {
      setImgSize(164)
    } else {
      setImgSize(214)
    }
  }, [windowSize])

  return (
    data && (
      <S.TestimonialsWrapper>
        <S.TestimonialsContainer imgSize={imgSize}>
          {data.decoration?.map((decor, i) => (
            <Decoration key={i} data={decor} />
          ))}

          <S.TestimonialsImgContainer imgSize={imgSize}>
            <img src={data.image} alt="" />
          </S.TestimonialsImgContainer>

          <S.TestimonialsTextContainer>
            <h2>{quote}</h2>
            <p className="name">{data.name}</p>
            <p>{position}</p>
          </S.TestimonialsTextContainer>
        </S.TestimonialsContainer>
      </S.TestimonialsWrapper>
    )
  )
}

export default Testimonials
