import React, { useEffect, useState } from 'react'
import * as S from './styles'

import FeatureIcon from '../FeatureIcon'

import { useTranslation } from 'react-i18next'

const FeatureItem = ({ data, baseURL }) => {
  const { t } = useTranslation()

  const [title, setTitle] = useState('')
  const [text, setText] = useState('')

  useEffect(() => {
    if (!data) return

    setTitle(t(data.title))
    setText(t(data.text))
  }, [t, data])

  return (
    data && (
      <S.FeatureItemContainer>
        <FeatureIcon name={data.icon} baseURL={baseURL} />
        <h3>{title}</h3>
        <p>{text}</p>
      </S.FeatureItemContainer>
    )
  )
}

export default FeatureItem
