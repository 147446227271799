import styled from 'styled-components'

export const FeatureIconContainer = styled.div`
  background: var(--blue12);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  padding: 0.5rem;
  margin-bottom: 1rem;

  @media only screen and (max-width: 700px) {
    margin-left: auto;
    margin-right: auto;
  }
`
