import styled from 'styled-components'

export const TestimonialsWrapper = styled.div`
  padding: 3rem 0 6rem;
`

export const TestimonialsContainer = styled.div`
  position: relative;
  max-width: calc(var(--unsignedMaxWidth) - ${props => props.imgSize / 2}px);
  background: var(--light4);
  border-radius: 50px;
  padding: 4rem 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: auto;
  margin-right: calc((100vw - var(--unsignedMaxWidth)) / 2);

  @media only screen and (max-width: 1366px) {
    max-width: calc(100vw - ${props => props.imgSize / 2}px - 4rem);
    margin-right: 2rem;
  }

  @media only screen and (max-width: 700px) {
    display: block;
    max-width: 100vw;
    margin: 0;
    border-radius: 0;
    text-align: center;
  }
`

export const TestimonialsTextContainer = styled.div`
  width: 80%;
  z-index: 1;
  position: relative;

  h2 {
    font-size: 32px;
    line-height: 46px;
    color: var(--navyblue2);
    margin-bottom: 1.5rem;
  }

  p {
    font-size: 16px;
    line-height: 30px;

    &.name {
      font-size: 18px;
      font-weight: 500;
      color: var(--blue11);
      margin-bottom: 0.5rem;
    }
  }

  @media only screen and (max-width: 1050px) {
    h2 {
      font-size: 24px;
      line-height: 30px;
    }
  }

  @media only screen and (max-width: 700px) {
    width: 100%;

    h2 {
      font-size: 22px;
    }
  }
`

export const TestimonialsImgContainer = styled.div`
  display: flex;
  align-items: center;
  width: ${props => props.imgSize}px;
  margin-left: calc(-${props => props.imgSize / 2}px - 3rem);
  z-index: 1;
  position: relative;

  img {
    width: 100%;
    border-radius: 50%;
    filter: drop-shadow(0px 4px 4px rgba(135, 132, 132, 0.25));
  }

  @media only screen and (max-width: 700px) {
    margin: calc(-${props => props.imgSize / 2}px - 4rem) auto 2rem;
  }
`
