import styled from 'styled-components'

export const PartnersContainer = styled.div`
  text-align: center;
  margin: 0 auto;
  padding: 6rem 0;

  h2 {
    font-size: 32px;
    font-weight: 600;
    color: var(--blue11);
    margin-bottom: 2rem;

    & > span.number {
      color: var(--blue2);
    }

    @media only screen and (max-width: 1050px) {
      font-size: 24px;
    }

    @media only screen and (max-width: 700px) {
      font-size: 20px;
      line-height: 30px;
    }
  }
`

export const SliderContainer = styled.div`
  margin: 0 auto;
  max-width: 60%;

  @media only screen and (max-width: 1023px) {
    max-width: 100%;
  }
`

export const IconsContainer = styled.div`
  display: flex;
  align-items: center;

  img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    filter: invert(97%) sepia(0%) saturate(122%) hue-rotate(156deg)
      brightness(86%) contrast(83%);
  }
`
