import styled from 'styled-components'

export const ContentItemContainer = styled.div`
  display: none;
  position: relative;
  opacity: 0;
  transition: 300ms all;

  &.visible {
    opacity: 1;
  }
`
export const ContentItemTextContainer = styled.div`
  width: 40%;
  margin-right: 2.5rem;
  padding-top: 6rem;

  h3 {
    font-size: 36px;
    font-weight: 600;
    margin: 1rem 0 1.5rem;
  }

  p {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 3rem;
    color: var(--grey14);
  }

  a {
    display: flex;
    align-items: center;
    justify-content: space-between;

    span:first-child {
      margin-right: 1rem;
    }
  }

  @media only screen and (max-width: 1050px) {
    width: 100%;

    h3 {
      font-size: 32px;
    }

    p {
      font-size: 16px;
      line-height: 24px;
    }
  }

  @media only screen and (max-width: 700px) {
    h3 {
      font-size: 24px;
    }
  }
`

export const ContentItemImgContainer = styled.div`
  display: flex;
  align-items: center;
  width: 60%;

  img,
  video {
    width: 100%;
    border-radius: 30px;
  }

  &.desktop-only {
    display: block;
  }

  &.mobile-only {
    display: none;
  }

  @media only screen and (max-width: 1050px) {
    width: 100%;

    &.desktop-only {
      display: none;
    }

    &.mobile-only {
      display: block;
    }
  }
`
