import styled from 'styled-components'

export const ContentSectionWrapper = styled.div`
  margin: 0 auto;
  margin-left: auto;
  padding: 3rem 0 6rem;

  & > *:not(:last-child) {
    margin-bottom: 4rem;
  }

  @media only screen and (max-width: 700px) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
`

export const ContentSectionContainer = styled.div`
  position: relative;
  background: ${props => props.color};
  padding: 4rem 3rem;
  border-radius: 90px;
  transition: 850ms all;
  margin-top: 10rem;
  opacity: 0;

  &.visible {
    margin-top: 0;
    opacity: 1;
  }

  & > * {
    z-index: 1;
  }

  h2 {
    font-size: 48px;
    text-align: center;
  }

  @media only screen and (max-width: 1050px) {
    h2 {
      font-size: 36px;
    }
  }

  @media only screen and (max-width: 700px) {
    padding: 4rem 2rem;
    border-radius: 0;

    h2 {
      font-size: 24px;
    }
  }
`
