import React, { useEffect, useState } from 'react'
import * as S from './styles'

import { useTranslation } from 'react-i18next'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const Partners = ({ data }) => {
  const { t } = useTranslation()

  const [title1, setTitle1] = useState('')
  const [title2, setTitle2] = useState('')
  const [title3, setTitle3] = useState('')

  useEffect(() => {
    setTitle1(t('subtitles.partners1'))
    setTitle2(t('subtitles.partners2'))
    setTitle3(t('subtitles.partners3'))
  }, [t])

  const SliderSettings = {
    draggable: false,
    swipe: false,
    arrows: false,
    dots: false,
    autoplay: true,
    pauseOnHover: false,
    speed: 1000,
    slidesToShow: 5,
    responsive: [{ breakpoint: 900, settings: { slidesToShow: 3 } }]
  }

  return (
    data && (
      <S.PartnersContainer className="mw-unsigned">
        <h2>
          <span>{title1}</span>
          <span className="number">{` ${data.userCount} `}</span>
          <span>{title2}</span>
          <span className="number">{` ${data.institutionCount} `}</span>
          <span>{title3}</span>
        </h2>

        <S.SliderContainer>
          <Slider {...SliderSettings}>
            {data.iconNames.map((name, i) => (
              <S.IconsContainer key={i}>
                <img
                  src={`${data.basePath}/${name}.${data.iconFormat}`}
                  alt={`Logo ${name}`}
                />
              </S.IconsContainer>
            ))}
          </Slider>
        </S.SliderContainer>
      </S.PartnersContainer>
    )
  )
}

export default Partners
