import React, { useEffect, useRef, useState } from 'react'
import * as S from './styles'

import { useWindowSize } from '@/contexts/WindowSizeContext'

import { useTranslation } from 'react-i18next'

const ContentMenu = ({ data, handler }) => {
  const { t } = useTranslation()
  const { windowSize } = useWindowSize()

  const [titles, setTitles] = useState([])

  const currentIndex = useRef()
  const timeout = useRef(null)
  const didInit = useRef(false)

  useEffect(() => {
    if (!data) return

    const translated = []
    data.content.map(item => translated.push(t(item.title)))
    setTitles(translated)
  }, [t, data])

  useEffect(() => {
    if (titles.length === 0 || !windowSize.width) return

    if (currentIndex.current === undefined) {
      selectOption(0)
    } else {
      applyVisualsToSelector(currentIndex.current)
    }

    if (didInit.current) return

    didInit.current = true
    if (titles.length > 1) setAutoplay()
  }, [titles, windowSize])

  const applyVisualsToSelector = index => {
    const title = titles[index]
    const btn = document.getElementById(`btn-${title}`)
    const bar = document.getElementById(`selector-${data.title}`)
    bar.style.width = `${btn?.clientWidth}px`
    bar.style.left = `${btn?.offsetLeft}px`
  }

  const selectOption = index => {
    currentIndex.current = index
    applyVisualsToSelector(index)
    handler(index)
  }

  const autoSelectNext = () => {
    let next = currentIndex.current + 1
    if (next >= titles.length) {
      next = 0
    }

    selectOption(next)
  }

  const setAutoplay = () => {
    if (timeout.current !== null) {
      console.warn(
        'ContentMenu error: tried to create overlapping timeouts!',
        timeout.current
      )
      return
    }

    timeout.current = setTimeout(() => {
      autoSelectNext()
      resetAutoplay()
    }, 10000)
  }

  const resetAutoplay = () => {
    clearTimeout(timeout.current)
    timeout.current = null
    setAutoplay()
  }

  return (
    data && (
      <S.ContentMenuContainer color={data.colorMenu}>
        <S.ButtonContainer>
          {titles.map((title, i) => (
            <button
              key={i}
              type="button"
              id={`btn-${title}`}
              onClick={() => {
                selectOption(i)
                if (titles.length > 1) resetAutoplay()
              }}
            >
              {title}
            </button>
          ))}
        </S.ButtonContainer>
        <div id={`selector-${data.title}`} className="selector"></div>
      </S.ContentMenuContainer>
    )
  )
}

export default ContentMenu
