import React, { useEffect, useState } from 'react'
import * as S from './styles'

import { useWindowSize } from '@/contexts/WindowSizeContext'

import FeatureItem from './FeatureItem'

import { useTranslation } from 'react-i18next'

const Features = ({ data }) => {
  const { t } = useTranslation()
  const { windowSize } = useWindowSize()

  const [title, setTitle] = useState('')
  const [itemRows, setItemRows] = useState()
  const [rowSize, setRowSize] = useState()

  useEffect(() => {
    if (!data) return
    setTitle(t(data.title))
  }, [t, data])

  useEffect(() => {
    if (!windowSize.width) return

    if (windowSize.width < 700) {
      setRowSize(1)
    } else if (windowSize.width < 1050) {
      setRowSize(2)
    } else {
      setRowSize(3)
    }
  }, [windowSize])

  useEffect(() => {
    if (!rowSize) return
    splitItemsInRows(rowSize)
  }, [rowSize])

  const splitItemsInRows = size => {
    if (data.items.length <= 0) return

    const rows = []
    while (data.items.length > 0) {
      const newRow = data.items.splice(0, size)
      rows.push(newRow)
    }

    setItemRows(rows)
  }

  return (
    itemRows && (
      <S.FeaturesContainer className="mw-unsigned">
        <h2>{title}</h2>
        {itemRows.map((row, i) => (
          <S.ItemsRow key={i} rowSize={rowSize}>
            {row.map((item, j) => (
              <FeatureItem key={j} data={item} baseURL={data.baseIconURL} />
            ))}
          </S.ItemsRow>
        ))}
      </S.FeaturesContainer>
    )
  )
}

export default Features
