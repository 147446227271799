import styled from 'styled-components'

export const ContentMenuContainer = styled.div`
  position: relative;
  margin: 0 auto;
  padding: 3rem 0 6rem;

  .selector {
    position: absolute;
    background: ${props => props.color};
    height: 4px;
    border-radius: 5px;
    width: 0px;
    left: 0px;
    transition: 250ms all;
  }
`
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 0.5rem;

  button {
    background: none;
    font-size: 18px;

    &:not(:last-child) {
      margin-right: 2.5rem;
    }
  }
`
