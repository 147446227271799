import React, { useEffect, useState } from 'react'

import Public from '@/helpers/PageTypes/Public'

import SEO from '@/components/SEO'
import BackToTopButton from '@/components/BackToTopButton'
import HeaderUnsigned from '@/components/LandingPage/Header'
import Newsletter from '@/components/LandingPage/Newsletter'
import FooterUnsigned from '@/components/LandingPage/Footer'
import LandingPage from '@/components/LandingPage/PageComponents/Landing'

import { useTranslation } from 'react-i18next'

const IndexPageV2 = () => {
  const { t } = useTranslation()

  const [pageTitle, setPageTitle] = useState(
    ' - Interactive Digital Content for Health'
  )

  useEffect(() => {
    setPageTitle(` - ${t('subtitles.tabText')}`)
  }, [t])

  return (
    <Public>
      <SEO title={pageTitle} />

      <HeaderUnsigned pageName={'landing'} />
      <LandingPage />
      <Newsletter />
      <FooterUnsigned />
      <BackToTopButton threshold={700} />
    </Public>
  )
}

export default IndexPageV2
