import { ASSET_PATHS, PAGE_PATHS } from '@/services/paths'
import { EVENT_KEYS, sendToDataLayer } from '@/services/events'

const ANIMATION_PATH = `${process.env.CDN_URL}/public/animations`
const CONTENT_PATH = `${process.env.CDN_URL}/public/contents`
const CONTENT_FORMAT = 'webp'

export const LANDINGPAGE_DATA = {
  banner: [
    {
      title: 'titles.platform',
      text: 'subtitles.platform',
      buttonText: 'buttons.askDemo',
      linkRoute: PAGE_PATHS.contact,
      buttonCallback: () => {
        sendToDataLayer({
          event: EVENT_KEYS.requestDemo
        })
      },
      image: `${CONTENT_PATH}/platform.${CONTENT_FORMAT}`,
      modal: `${process.env.CDN_URL}/public/bioatlas/viewer`,
      decoration: [
        {
          type: ASSET_PATHS.dots_grid,
          color:
            'invert(73%) sepia(54%) saturate(1655%) hue-rotate(327deg) brightness(102%) contrast(90%)',
          opacity: 0.5,
          width: '196px',
          height: '156px',
          pos: { x: '17%', y: '12%' },
          responsive: [
            {
              breakpoint: 700,
              width: '146px',
              height: '106px',
              pos: { x: '20%', y: '15%' }
            },
            { breakpoint: 1050, pos: { x: '20%', y: '15%' } }
          ]
        },
        {
          type: ASSET_PATHS.dots_grid,
          color:
            'invert(54%) sepia(22%) saturate(1266%) hue-rotate(201deg) brightness(96%) contrast(90%)',
          opacity: 0.5,
          width: '196px',
          height: '156px',
          pos: { x: '88%', y: '30%' },
          responsive: [
            {
              breakpoint: 700,
              width: '146px',
              height: '106px',
              pos: { x: '82%', y: '30%' }
            },
            { breakpoint: 1050, pos: { x: '77%', y: '35%' } }
          ]
        }
      ]
    },
    {
      title: 'titles.modules',
      text: 'subtitles.modules',
      buttonText: 'buttons.knowModules',
      linkRoute: PAGE_PATHS.contents,
      image: `${CONTENT_PATH}/modules.${CONTENT_FORMAT}`,
      decoration: [
        {
          type: 'circle',
          color: 'rgba(151, 52, 173, 0.05)',
          width: '522px',
          pos: { x: '50%', y: '50%' },
          responsive: [
            { breakpoint: 700, width: '322px' },
            { breakpoint: 1050, width: '422px' }
          ]
        }
      ]
    },
    {
      title: 'titles.cadaver',
      text: 'subtitles.cadaver',
      buttonText: 'buttons.knowCadaver',
      linkRoute: `${PAGE_PATHS.contents}?section=0&subsection=0`,
      image: `${CONTENT_PATH}/cadaver.${CONTENT_FORMAT}`,
      decoration: [
        {
          type: ASSET_PATHS.dots_grid,
          color:
            'invert(73%) sepia(54%) saturate(1655%) hue-rotate(327deg) brightness(102%) contrast(90%)',
          opacity: 0.5,
          width: '196px',
          height: '156px',
          pos: { x: '25%', y: '40%' },
          responsive: [
            {
              breakpoint: 700,
              width: '156px',
              height: '106px',
              pos: { x: '20%', y: '72%' }
            },
            { breakpoint: 1050, pos: { x: '10%', y: '72%' } }
          ]
        },
        {
          type: ASSET_PATHS.dots_grid,
          color:
            'invert(54%) sepia(22%) saturate(1266%) hue-rotate(201deg) brightness(96%) contrast(90%)',
          opacity: 0.5,
          width: '196px',
          height: '156px',
          pos: { x: '66%', y: '21%' },
          responsive: [
            {
              breakpoint: 700,
              width: '156px',
              height: '106px',
              pos: { x: '75%', y: '21%' }
            },
            { breakpoint: 1050, pos: { x: '75%', y: '21%' } }
          ]
        }
      ]
    },
    {
      title: 'titles.blades',
      text: 'subtitles.blades',
      buttonText: 'buttons.knowMore',
      linkRoute: `${PAGE_PATHS.contents}?section=1&subsection=1`,
      image: `${CONTENT_PATH}/histology3.${CONTENT_FORMAT}`,
      decoration: [
        {
          type: 'circle',
          color: 'rgba(242, 153, 74, 0.12)',
          width: '510px',
          pos: { x: '50%', y: '50%' },
          responsive: [
            { breakpoint: 700, width: '310px' },
            { breakpoint: 1050, width: '410px' }
          ]
        }
      ]
    },
    {
      title: 'titles.simulations',
      text: 'subtitles.simulations',
      buttonText: 'buttons.knowMore',
      linkRoute: `${PAGE_PATHS.contents}?section=3&subsection=2`,
      image: `${CONTENT_PATH}/simulator.${CONTENT_FORMAT}`,
      decoration: [
        {
          type: ASSET_PATHS.dots_grid,
          color:
            'invert(73%) sepia(54%) saturate(1655%) hue-rotate(327deg) brightness(102%) contrast(90%)',
          opacity: 0.5,
          width: '196px',
          height: '156px',
          pos: { x: '17%', y: '70%' },
          responsive: [
            {
              breakpoint: 700,
              width: '156px',
              height: '106px',
              pos: { x: '18%', y: '70%' }
            },
            { breakpoint: 1050, pos: { x: '7%', y: '70%' } }
          ]
        },
        {
          type: ASSET_PATHS.dots_grid,
          color:
            'invert(54%) sepia(22%) saturate(1266%) hue-rotate(201deg) brightness(96%) contrast(90%)',
          opacity: 0.5,
          width: '196px',
          height: '156px',
          pos: { x: '62%', y: '32%' },
          responsive: [
            {
              breakpoint: 700,
              width: '156px',
              height: '106px',
              pos: { x: '63%', y: '32%' }
            },
            { breakpoint: 1050, pos: { x: '72%', y: '32%' } }
          ]
        }
      ]
    }
  ],

  partners: {
    userCount: '35.000',
    institutionCount: '100',
    basePath: `${process.env.CDN_URL}/public/logos`,
    iconFormat: 'webp',
    iconNames: [
      'agostinho',
      'medicas',
      'medcel',
      'ipemed',
      'iesvap',
      'facimpa',
      'fesar',
      'fmit',
      'lucas',
      'redentor'
    ]
  },

  contentSections: [
    {
      title: 'titles.organs',
      color: 'rgba(251, 95, 96, 0.05)',
      colorMenu: '#FB5F60',
      decoration: [
        {
          type: ASSET_PATHS.dots_grid,
          color:
            'invert(73%) sepia(54%) saturate(1655%) hue-rotate(327deg) brightness(102%) contrast(90%);',
          opacity: 0.28,
          width: '196px',
          height: '156px',
          pos: { x: '91%', y: '85%' },
          responsive: [
            {
              breakpoint: 700,
              width: '102px',
              height: '156px',
              pos: { x: '87%', y: '32%' }
            },
            { breakpoint: 1050, pos: { x: '87%', y: '24%' } }
          ]
        }
      ],
      content: [
        {
          title: 'titles.completeAnatomy',
          text: 'subtitles.completeAnatomy',
          buttonText: 'buttons.knowContent',
          image: `${ANIMATION_PATH}/complete_anatomy`,
          available: ['Web', 'App', 'VR']
        },
        {
          title: 'titles.topographicAnatomy',
          text: 'subtitles.topographicAnatomy',
          buttonText: 'buttons.knowContent',
          image: `${ANIMATION_PATH}/topographic_anatomy`,
          available: ['Web', 'App', 'VR']
        },
        {
          title: 'titles.systemicAnatomy',
          text: 'subtitles.systemicAnatomy',
          buttonText: 'buttons.knowContent',
          image: `${ANIMATION_PATH}/systemic_anatomy`,
          available: ['Web', 'App', 'VR']
        }
      ]
    },
    {
      title: 'titles.cellStructure',
      color: 'rgba(151, 52, 173, 0.05)',
      colorMenu: '#9734AD',
      decoration: [
        {
          type: 'circle',
          color: 'rgba(151, 52, 173, 0.1)',
          width: '384px',
          pos: { x: '84%', y: '44%' },
          responsive: [
            { breakpoint: 700, width: '202px', pos: { x: '80%', y: '28%' } },
            { breakpoint: 1050, width: '372px', pos: { x: '80%', y: '28%' } }
          ]
        }
      ],
      content: [
        {
          title: 'titles.cytology',
          text: 'subtitles.cytology',
          buttonText: 'buttons.knowMore',
          image: `${ANIMATION_PATH}/cytology`,
          available: ['Web', 'App']
        },
        {
          title: 'titles.histology',
          text: 'subtitles.histology',
          buttonText: 'buttons.knowMore',
          image: `${ANIMATION_PATH}/histology`,
          available: ['Web', 'App']
        }
      ]
    },
    {
      title: 'titles.development',
      color: 'rgba(12, 181, 107, 0.05)',
      colorMenu: '#0CB56B',
      decoration: [
        {
          type: ASSET_PATHS.dots_grid,
          color:
            'invert(49%) sepia(79%) saturate(2248%) hue-rotate(121deg) brightness(96%) contrast(91%)',
          opacity: 0.2,
          width: '196px',
          height: '156px',
          pos: { x: '91%', y: '39%' },
          responsive: [
            {
              breakpoint: 700,
              width: '102px',
              height: '156px',
              pos: { x: '87%', y: '30%' }
            },
            { breakpoint: 1050, pos: { x: '87%', y: '24%' } }
          ]
        }
      ],
      content: [
        {
          title: 'titles.embryology',
          text: 'subtitles.embryology',
          buttonText: 'buttons.knowMore',
          image: `${ANIMATION_PATH}/embryology`,
          available: ['Web', 'App']
        },
        {
          title: 'titles.physiology',
          text: 'subtitles.physiology',
          buttonText: 'buttons.knowMore',
          image: `${ANIMATION_PATH}/physiology`,
          available: ['Web', 'App']
        }
      ]
    },
    {
      title: 'titles.specialties',
      color: 'rgba(128, 129, 232, 0.05)',
      colorMenu: '#8081E8',
      decoration: [
        {
          type: 'circle',
          color: 'rgba(128, 129, 232, 0.1)',
          width: '368px',
          pos: { x: '85%', y: '74%' },
          responsive: [
            { breakpoint: 700, width: '202px', pos: { x: '20%', y: '28%' } },
            { breakpoint: 1050, width: '372px', pos: { x: '20%', y: '28%' } }
          ]
        }
      ],
      content: [
        {
          title: 'titles.radiology',
          text: 'subtitles.radiology',
          buttonText: 'buttons.knowContent',
          image: `${ANIMATION_PATH}/radiology`,
          newUntil: '08/01/2023',
          available: ['Web']
        },
        {
          title: 'titles.pathology',
          text: 'subtitles.pathology',
          buttonText: 'buttons.knowContent',
          image: `${ANIMATION_PATH}/pathology`,
          available: ['Web']
        },
        {
          title: 'titles.simulator',
          text: 'subtitles.simulator',
          buttonText: 'buttons.knowContent',
          image: `${ANIMATION_PATH}/simulator`,
          available: ['Web']
        }
      ]
    }
  ],

  features: {
    title: 'subtitles.features',
    baseIconURL: `${process.env.CDN_URL}/public/icons/features`,
    items: [
      { icon: 'vr', title: 'titles.VR', text: 'subtitles.VR' },
      {
        icon: 'platform',
        title: 'titles.multiplatform',
        text: 'subtitles.multiplatform'
      },
      {
        icon: 'animation',
        title: 'titles.animations',
        text: 'subtitles.animations'
      },
      {
        icon: 'control',
        title: 'titles.accessControl',
        text: 'subtitles.accessControl'
      },
      {
        icon: 'integration',
        title: 'titles.integration',
        text: 'subtitles.integration'
      },
      {
        icon: 'languages',
        title: 'titles.languages',
        text: 'subtitles.languages'
      }
    ]
  },

  testimonials: {
    image: `${CONTENT_PATH}/prof.${CONTENT_FORMAT}`,
    name: 'Dr. Rodolfo Souza de Faria',
    decoration: [
      {
        type: ASSET_PATHS.dots_grid,
        color:
          'invert(91%) sepia(9%) saturate(219%) hue-rotate(206deg) brightness(100%) contrast(100%)',
        opacity: 1,
        width: '196px',
        height: '156px',
        pos: { x: '2%', y: '37%' },
        responsive: [
          {
            breakpoint: 700,
            width: '102px',
            height: '106px',
            pos: { x: '50%', y: '-5%' }
          }
        ]
      },
      {
        type: ASSET_PATHS.quotes,
        color:
          'invert(90%) sepia(2%) saturate(2484%) hue-rotate(204deg) brightness(106%) contrast(99%)',
        opacity: 1,
        width: '164px',
        height: '100px',
        pos: { x: '20%', y: '20%' },
        responsive: [
          {
            breakpoint: 700,
            width: '124px',
            height: '60px',
            pos: { x: '18%', y: '12%' }
          },
          { breakpoint: 1050, width: '134px', height: '70px' }
        ]
      },
      {
        type: ASSET_PATHS.quotes,
        color:
          'invert(90%) sepia(2%) saturate(2484%) hue-rotate(204deg) brightness(106%) contrast(99%)',
        opacity: 1,
        rotation: '180deg',
        width: '164px',
        height: '100px',
        pos: { x: '90%', y: '80%' },
        responsive: [
          {
            breakpoint: 700,
            width: '124px',
            height: '60px',
            pos: { x: '85%', y: '70%' }
          },
          { breakpoint: 1050, width: '134px', height: '70px' }
        ]
      }
    ]
  }
}
