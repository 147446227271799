import styled from 'styled-components'

export const FeaturesContainer = styled.div`
  margin: 0 auto;
  padding: 6rem 0;

  h2 {
    font-size: 50px;
    font-weight: 800;
    line-height: 61px;
    color: var(--dark);
    margin-bottom: 6rem;
    text-align: center;
  }

  @media only screen and (max-width: 1050px) {
    h2 {
      font-size: 36px;
      line-height: 44px;
    }
  }

  @media only screen and (max-width: 700px) {
    padding: 3rem 0;

    h2 {
      font-size: 24px;
      line-height: 34px;
    }
  }
`

export const ItemsRow = styled.div`
  display: flex;
  justify-content: space-between;

  & > * {
    width: calc(100% / ${props => props.rowSize} - 2.5rem);

    @media only screen and (max-width: 700px) {
      width: 100%;
    }
  }

  &:not(:last-child) {
    margin-bottom: 3rem;
  }
`
